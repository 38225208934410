import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Season } from '@wbk/api';
import { Button, ImageWithFallback } from '@wbk/ui';
import { useAnalytics } from '@wbk/analytics';

type Props = {
  season: Season;
} & React.HTMLAttributes<HTMLElement>;

const SeasonListItem = ({ season, ...props }: Props) => {
  const { t } = useTranslation();
  const { generateLeadEvent } = useAnalytics();

  return (
    <Button
      as={Link}
      theme='secondary'
      href={`/season/${season.slug}`}
      onClick={() => {
        generateLeadEvent({
          event_start_price: season.event_start_price + season.event_start_vat,
          currency: season.currency,
          title: season.title,
          slug: season.slug,
        });
      }}
      className='flex w-full grow flex-col items-center gap-6 p-4 md:flex-row'
      {...props}
    >
      <div className='flex w-full grow justify-center gap-4 md:items-center'>
        <div className='flex w-full flex-col items-center gap-6 md:flex-row'>
          <div className='relative flex aspect-[3/2] w-full overflow-hidden rounded-md md:max-w-40'>
            <ImageWithFallback
              src={season?.poster}
              className='w-full object-cover'
              sizes='220px'
              alt={season?.title || ''}
            />
          </div>

          <div className='space-y-1 text-start'>
            <p className='text-2xl md:text-xl lg:text-2xl'>
              <strong>{season.title}</strong>
            </p>
            {season.sub_title && (
              <p className='line-clamp-1 max-w-md text-base'>{season.sub_title}</p>
            )}
          </div>
        </div>
      </div>
      <Button
        className='w-full whitespace-nowrap text-base font-bold md:max-w-[140px] md:!py-1'
        arrow
        tabIndex={-1}
        theme='action'
      >
        {t('event:buy_tickets')}
      </Button>
    </Button>
  );
};

export default SeasonListItem;
